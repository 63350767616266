<template>
  <modify-apply-block
    data-type="worker_certificate_pro_qua"
    ref="worker_certificate_pro_qua_p"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :getTitle="getTitle"
    :form-parms-add="formParmsAdd"
    :dataFileParm="{docName: '专业技术资格证书', isDoc: true }"
    block-name="专业技术资格证书"
    @dataFileUpdate="dataFileUpdate"
    :init-add-form-data="initAddFormData"
    :deal-data-info="dealDataInfo"
    ref-name="worker_certificate_pro_qua"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerCertificateRequest
} from '../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
    this.$store.dispatch('loadCertificateList')
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['worker_certificate_pro_qua_p'].openForm) {
      this.$refs['worker_certificate_pro_qua_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await workerCertificateRequest.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'pro_qua'
      }
    },
    dealDataInfo (data) {
      let jbData = this.$store.getters.certificateList.find(v => v.key === data.certificateId)
      if (jbData) {
        data.certificateTitle = jbData.data.title
      }
      return data
    },
    async getEdit () {
      if (this.workerId) {
        return await workerCertificateRequest.getEdit({
          type: 'pro_qua',
          workerId: this.workerId
        })
      }
    },
    dealParm (parm) {
      if (typeof parm.certificateId === 'string') {
        parm.certificateId = this.$store.getters.certificateList.find(v => v.label === parm.certificateId).key
      }
      return parm
    },
    async saveEdit (parm) {
      return await workerCertificateRequest.saveEdit(Object.assign({}, this.dealParm(parm), {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await workerCertificateRequest.addEdit(this.dealParm(parm))
    },
    getTitle (data) {
      return (data.certificateTitle || '') + ' ' + (data.getDate ? data.getDate.slice(0, 10) : '')
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '系列名称/类别',
          key: 'xlmc'
        },
        {
          type: 'input',
          label: '专业名称',
          key: 'pro'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.certificateList.map(v => ({value: v.key, label: v.label})),
          label: '资格证书',
          key: 'certificateId',
          check: {
            required: true
          },
          format: (data) => {
            return data.certificateTitle
          }
        },
        // {
        //   type: 'input',
        //   label: '管理号',
        //   key: 'gradSchool'
        // },
        {
          type: 'datePicker',
          label: '授予(批准)时间',
          key: 'getDate'
        },
        {
          type: 'input',
          label: '批准文号',
          key: 'practiceType'
        },
        {
          type: 'datePicker',
          label: '发证时间',
          key: 'hireStartDate'
        }]
        return data
      }
    }
  }
}
</script>
